@font-face {
  font-family: 'Haas Grotesk';
  src: url('../fonts/NHaasGroteskDSStd-75Bd.eot');
  src: url('../fonts/NHaasGroteskDSStd-75Bd.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSStd-75Bd.woff') format('woff'),
  url('../fonts/NHaasGroteskDSStd-75Bd.ttf') format('truetype'),
  url('../fonts/NHaasGroteskDSStd-75Bd.svg#NHaasGroteskDSStd-75Bd') format('svg'),
  url('../fonts/NHaasGroteskDSStd-75Bd.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'Haas Grotesk';
  src: url('../fonts/NHaasGroteskDSStd-55Rg.eot');
  src: url('../fonts/NHaasGroteskDSStd-55Rg.woff2') format('woff2'),
  url('../fonts/NHaasGroteskDSStd-55Rg.woff') format('woff'),
  url('../fonts/NHaasGroteskDSStd-55Rg.ttf') format('truetype'),
  url('../fonts/NHaasGroteskDSStd-55Rg.svg#NHaasGroteskDSStd-55Rg') format('svg'),
  url('../fonts/NHaasGroteskDSStd-55Rg.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;
  font-weight: bold;
}

$min_width: 767px;
$max_width: 1600px;
$min_font: 44px;
$max_font: 144px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}