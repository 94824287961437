.scene {
  padding: 0 15px;
  z-index: -1;
  transform-style: preserve-3d;

  &--hidden {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    z-index: -10000;
  }

  &__top-wrapper,
  &__bottom-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    transform-style: preserve-3d;
    flex: 1 1 50%;

    @media(max-width: 768px) {
      flex-wrap: wrap;
      overflow: hidden;
      padding: 0;
    }

    @media(max-width: 374px) {
      padding: 0
    }
  }

  .scene-header {
    font-weight: bold;
    line-height: 1;

    @include fluid-type($min_width, $max_width, 50px, $max_font);

    @media(max-width: 768px) {
      margin-bottom: 10px;

      br {
        display: none;
      }
    }

    @media(max-width: 340px) {
      font-size: 26px;
      margin-bottom: 5px;
    }
  }

  &__bottom-container, &__top-container {
    display: flex;
    width: 100%;
    @media(max-width: 768px) {
      padding: 0;
      flex-direction: column;
    }
  }

  &__bottom-container {
    align-items: flex-end;
    transform-style: preserve-3d;

    @media(max-width: 768px) {
      align-items: flex-start;
    }
  }

  &__top-container {
    align-items: flex-start;
    padding-bottom: 30px;
    overflow: hidden;
    @media(max-width: 1280px) {
      padding-bottom: 10px;
    }

    @media(max-width: 992px) {
      padding-bottom: 30px;
    }

    @media(max-width: 767px) {
      padding-bottom: 0;
    }
  }

  &__top-wrapper {
    align-items: flex-end;

    @media(max-width: 768px) {
      flex-basis: 43%;
    }

    @media(max-width: 350px) {
      flex-basis: 37%;
    }

    .scene-text-wrapper {
      overflow: hidden;
      flex-basis: 40%;

      @media(max-width: 992px) {
        flex-basis: 100%;
      }

      .scene-text {
        margin: 20px 25px 0 40px;

        @include fluid-type($min_width, $max_width, 14px, 18px);

        @media(max-width: 768px) {
          margin: 0;
        }

        @media(max-width: 374px) {
          font-size: 12px;
        }
      }
    }
  }

  &__bottom-wrapper {
    align-items: flex-start;
    overflow: visible;


    @media(max-width: 1280px) {
      padding-top: 5px;
    }

    @media(max-width: 768px) {
      flex-wrap: wrap;
      overflow: visible;
      flex-basis: 53%;
      padding-top: 0;

    }
  }

  .scene-graphics {
    position: relative;
    flex-basis: 35%;
    display: flex;
    align-items: flex-end;
    transform-style: preserve-3d;

    height: 275px;

    @media(max-width: 1489px) {
      height: 220px;
    }

    @media(max-width: 1280px) {
      height: 200px;
    }

    @media(max-width: 500px) {
      height: 100px;
    }

    @media(max-width: 350px) {
      height: 90px;
    }

    > svg {
      display: block;
      overflow: hidden;
      flex: 1 1 auto;
      max-height: 100%;
      @media (max-width: 768px) {
        width: 70%;
        flex-grow: 0;
      }
    }
  }

  .big-number-wrapper {
    display: flex;
    align-items: flex-end;
    flex-basis: 65%;
    padding-bottom: 1px;
    padding-top: 20px;
    overflow: hidden;

    @media(max-width: 768px) {
      flex-basis: 100%;
      margin-top: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      overflow: hidden;
    }

    @media(max-width: 350px) {
      margin-top: 5px;
    }

    .big-number {
      padding: 0 20px 0 40px;

      @media(max-width: 768px) {
        padding-left: 0;
        padding-right: 10px;
      }

      h2 {
        line-height: .8;
        white-space: nowrap;

        @include fluid-type($min_width, $max_width, 110px, 280px);

        span {
          display: inline-block;
          background-color: rgba(255,255,255,1);
        }

        @media(max-width: 374px) {
          font-size: 70px
        }
      }
    }

    .big-number-info {
      font-weight: bold;

      &__percent {
        display: block;

        @include fluid-type($min_width, $max_width, 50px, 120px);

        @media(max-width: 768px) {
          line-height: 1;
        }

        @media(max-width: 400px) {
          font-size: 40px;
        }

        @media(max-width: 374px) {
          font-size: 30px;
        }
      }

      &__text {
        display: block;
        font-size: 16px;
        background-color: white;

        @include fluid-type($min_width, $max_width, 14px, 16px);

        @media(max-width: 768px) {
          font-size: 14px;
          padding-left: 25px;
          line-height: 1.1;
        }

        @media(max-width: 374px) {
          font-size: 12px;

          br {
            display: none;
          }
        }
      }
    }
  }
}

$stackedScenesList: scene-efficiency scene-mobility scene-innovation scene-growth scene-collaboration;
@each $stackedScene in $stackedScenesList {
  ##{$stackedScene}:not(.scene--hidden) {
    $i: index($stackedScenesList, $stackedScene) - 1;
    transform: translateZ(300px * $i)
  }
  ##{$stackedScene}-connector:not(.scene--hidden) {
    $i: index($stackedScenesList, $stackedScene) - 1;
    transform: translateZ(300px * $i)
  }
}

.connector-line {
  flex: 0 0 auto !important;
  width: 1px !important;
  height: 1px !important;
  overflow: visible !important;
  position: absolute;
  z-index: 1;
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 2px;
    top: 0;
    overflow: visible !important;
    stroke-width: 3px;
    stroke: black;
  }
  line {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
  }
}
