.video-carousel {
  width: 100%;
  padding-bottom: 50px;
  cursor: pointer;

  @media(max-width: 768px) {
    padding-bottom: 40px;
  }

  @media(max-width: 350px) {
    padding-bottom: 20px;
  }

  .carousel-slide {
    margin: 5px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @media(max-width: 992px) {
      img {
        width: 100%;
      }
    }
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    bottom: -20px;
    top: auto;

    @media(max-width: 350px) {
      bottom: -20px;
      width: 25px;
      height: 25px;
    }

    &::before {
      content: '';
      display: block;
      opacity: 1;
      background-size: cover;
      width: 40px;
      height: 40px;

      @media(max-width: 768px) {
        width: 30px;
        height: 30px;
      }

      @media(max-width: 350px) {
        width: 20px;
        height: 20px;
      }
    }

    &.slick-prev {
      right: 105px;
      left: auto;

      @media(max-width: 992px) {
        right: 80px;
      }

      @media(max-width: 768px) {
        right: 70px;
      }

      @media(max-width: 350px) {
        right: 60px;
      }

      &::before {
        background-image: url("../img/prev-arrow.svg");
      }
    }

    &.slick-next {
      right: 17px;

      @media(max-width: 992px) {
        right: 0;
      }

      &::before {
        background-image: url("../img/next-arrow.svg");
      }
    }
  }
}

.slick-disabled {
  opacity: 0;
  pointer-events:none;
}

.slide-count-wrapper {
  height: 0;
  position: relative;

  .slide-count {
    position: absolute;
    right: 50px;
    top: -29px;
    font-size: 14px;

    @media(max-width: 350px) {
      right: 35px;
      top: -14px;
    }
  }
}