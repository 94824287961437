.scene--efficiency {
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .scene-graphics > svg.intro__draw-line {
    right: 0;
    opacity: 1;
    width: 1px;
    flex: 0 0 auto;
    height: 1px;
    margin-bottom: -2px;
    margin-left: 7px;

    @media(max-width: 768px) {
      margin-left: 12px;
    }
  }

  .scene__top-wrapper {
    .scene-text-wrapper {
      flex-basis: 40%;

      @media(max-width: 768px) {
        flex-basis: auto;
      }
    }
  }

  .scene__bottom-wrapper {
    @media(max-width: 768px) {

      .scene-graphics {
        flex-basis: auto;
        width: 100%;
      }

      .big-number {
        flex-basis: auto;
      }

      .big-number-info {
        flex-basis: auto;

        .big-number-info__text, .big-number-info__percent {
          padding-left: 20px;
        }
      }
    }
  }
}
