.globalclientfooter {
  min-height: 477px;
  position: fixed;
  width: 100%;
  left: 0;
  overflow: auto;
  bottom: 0;
  opacity: 0;
  max-height: 100vh;
  z-index: 99;
}

footer {
  min-height: 477px;
  background-color: black;
  width: 100%;
  height: 100%;
}
