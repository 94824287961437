.scene--connected-world {
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-content: center;
  transform-origin: top left;
  flex-wrap: wrap;

  perspective: 300px;
  perspective-origin: -300px 100px;

  @media(max-width: 992px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

  }

  @media(max-width: 768px) {
    perspective-origin: -150px -100px;
  }

  .scene-header-wrapper {
    max-width: 50%;
    z-index: 4;

    @media(max-width: 992px) {
      max-width: 100%;
      flex-basis: auto;
      flex-grow: 0;
    }
  }

  .scene-header {

    @media(max-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .partner-info-wrapper {
    padding: 10px 0 0 50px;
    z-index: 2;
    max-width: 50%;

    @media(max-width: 992px) {
      flex-basis: auto;
      flex-grow: 0;
      max-width: 100%;
      justify-self: flex-end;

      padding: 0;
    }

    .partner-info-wrapper__header {
      margin-bottom: 10px;
      font-size: 26px;

      @media(max-width: 1600px) {
        margin-bottom: 10px;
        font-size: 18px;
      }
      @media(max-width: 768px) {
        margin-bottom: 5px;
      }
    }

    .partner-info-wrapper__logo {
      width: 60%;

      @media(max-width: 1600px) {
        width: 50%;
      }
    }

    .partner-info-wrapper__text {
      font-size: 24px;
      margin-top: 10px;

      @media(max-width: 1600px) {
        font-size: 18px;
      }

      @media(max-width: 768px) {
        margin-top: 5px;
      }

      a {
        color: currentColor;
        text-decoration: underline;
        transition: opacity .2s;

        &:hover {
          opacity: .6;
        }
      }
    }

    &--mobile {
      padding: 0;

      .partner-info-wrapper__header,
      .partner-info-wrapper__text {
        font-size: 14px;

        @media(min-width: 768px) and (max-width: 992px) {
          font-size: 16px;
        }

        @media(max-width: 350px) {
          font-size: 12px;
        }
      }

      .partner-info-wrapper__logo {
        width: 120px;

        @media(min-width: 768px) and (max-width: 992px) {
          width: 160px;
        }
      }
    }
  }

  .scene__video-wrapper {
    overflow: visible;
    position: relative;
    margin-right: auto;
    z-index: 3;

    max-width: 100%;



    @media(max-width: 768px) {
      max-width: 100%;
      padding-top: 5px;
    }

    @media(min-width: 992px) and (max-width: 1099px) {
      max-width: 700px;
      padding-top: 0;
    }

    @media(min-width: 1100px) and (max-width: 1319px) {
      max-width: 800px;
      padding-top: 0;
    }

    @media(min-width: 1320px) and (max-width: 1800px) {
      max-width: 950px;
      padding-top: 0;
    }
  }
}
