.scene--end-card {
  height: 100%;
  max-width: 670px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: -1;

  h1 {
    font-weight: normal;
    white-space: nowrap;
    margin-top: 10px;
    font-size: 34px;
  }

  .end-card__info-wrapper {
    display: flex;
    align-items: center;

    @media(max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }

    .info-icon {
      flex-basis: 25%;
      position: relative;

      @media(max-width: 768px) {
        margin: 0 auto 20px auto;
      }

      svg {
        width: 75%;
        margin: 0 auto;

        @media(max-width: 768px) {
          width: 40%;
        }

        @media(max-width: 374px) {
          width: 25%;
        }
      }

      .circle-wave {
        content: '';
        display: block;
        height: 40px;
        width: 40px;
        border: 3px solid red;
        border-radius: 50%;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        z-index: -1;
      }
    }

    .info-header {
      flex-basis: 75%;
      overflow: hidden;
      padding: 0 0 0 30px;

      @media(max-width: 768px) {
        padding: 0;
        margin: 0 auto;
      }

      &__image {
        overflow: hidden;

        @media(max-width: 768px) {
          width: 250px;
          margin: 0 auto;
        }
      }
    }
  }
}

.end-card__input-wrapper {
  margin-top: 100px;

  @media(max-width: 768px) {
    margin-top: 50px;
  }

  @media(max-width: 374px) {
    margin-top: 30px;
  }

  .end-card-input-header {
    margin-bottom: 40px;
    overflow: hidden;

    @media(max-width: 768px) {
      margin-bottom: 15px;
      padding-bottom: 5px;
      font-size: 14px;
      text-align: center;
    }
  }

  .signup-form-message {
    width: 100%;

    p {
      font-weight: normal;
      white-space: nowrap;
      margin-top: 10px;
      font-size: 34px;
      text-align: center;

      @media(max-width: 768px) {
        margin-bottom: 15px;
        padding-bottom: 5px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .input-group {
    width: 100%;
    display: block;
  }

  .mktoForm {
    width: 100% !important;
    max-height: 60px;

    label {
      display: none;
    }

    @media(max-width: 480px) {
      padding: 10px 0;
    }

    .mktoError {
      bottom: 40px !important;
      right: auto !important;
      .mktoErrorMsg {
        box-shadow: none;
        background-image: none;
        background-color: $verizon-red;
        text-shadow: none;
        border-radius: 0;
        border: none;
      }

      .mktoErrorArrowWrap {
        top: 98%;
        transform: rotate(180deg);

        .mktoErrorArrow {
          background-color: $verizon-red;
          border-color: $verizon-red;
        }
      }
    }

    .mktoFormCol,
    .mktoFieldWrap {
      width: 100%;
    }

    .mktoGutter,
    .mktoOffset {
      display: none;
    }

    .mktoEmailField {
      background-color: transparent;
      border: none;
      border-bottom: 3px solid black;
      border-radius: 0;
      box-shadow: none;
      font-size: 18px;
      font-style: italic;
      height: 60px;
      padding-left: 0;
      width: 100% !important;
      padding-right: 5ch;
      &:focus {
        outline: none;
      }

      &::-ms-clear { display: none; }
      @media(max-width: 768px) {
        font-size: 16px;
        height: 40px;
        border-width: 2px;
      }
    }

    .mktoButtonWrap.mktoSimple .mktoButton {
      position: absolute;
      right: 0;
      height: 57px;
      width: auto;
      margin-left: -24px;
      border-radius: 0;
      box-shadow: none;
      display: flex;
      align-items: center;
      opacity: 1;
      z-index: 3;
      border: none;
      padding: 0 35px 0 1ch;
      top: 0;
      background-image: none;
      overflow: hidden;
      color: $verizon-black;
      background-color: white;

      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-right: 2px solid $verizon-black;
        border-bottom: 2px solid $verizon-black;
        transform: rotate(-45deg);
        margin-left: 0;
        margin-bottom: -2px;
        position: absolute;
        right: 5px;
      }

      &:hover {
        border: none;
      }

      &:focus,
      &:active {
        background-image: none;
        background-color: transparent;
        border: none;
      }

      @media(max-width: 768px) {
        height: 40px;
        width: 65px;
        top: 0;
      }

      @media(max-width: 480px) {
        right: 0;
        top: 7px;
      }
    }

    .mktoButtonRow {
      position: static;
    }
  }
}
