.video-popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: -2;
  opacity: 0;
  transition: opacity .5s;



  &.visible {
    z-index: 9;
    opacity: 1;
    filter: blur(0);
  }

  .video-content {
    width: 60%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;

    @media(max-width: 768px) {
      width: calc(100% - 30px)
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  .youtube-embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: none;
  transition: opacity .2s;

  img {
    width: 30px;
    height: 30px;
  }

  &:hover {
    opacity: .6;
  }
}