body {
  color: $verizon-black;
  font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;

  touch-action: manipulation;
}

.scene-wrapper {
  position: fixed;
  bottom: 0;
  top: 160px;
  left: 0;
  right: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s;

  z-index: 0;

  &--3d {
    perspective-origin: -1100px -100px;
    perspective: 300px;
  }

  &--full-page {
    bottom: 0 !important;
  }

  @media(max-width: 992px) {
    top: 160px;
    bottom: 34px;
  }

  @media(max-width: 768px) {
    top: 120px;
    bottom: 34px;
  }
}

.scene {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;

  &.video-popup-open {
    filter: blur(10px);
  }
}

.hidden-xxs {
  @media (max-width: 370px) {
    display: none;
  }
}