.signup-widget {
  background-color: $verizon-black;
  border-radius: 10px 0 0 10px;
  width: 100px;
  height: 80px;
  padding: 17px 20px 17px 14px;
  position: fixed;
  right: 0;
  bottom: 10%;
  z-index: 9;
  overflow: hidden;
  transform: translateX(100%);
  transition: width .3s, transform .8s;

  label {
    display: none;
  }

  .mktoField::-ms-clear {
    display: none;
  }
  
  .mktoForm .mktoError {
    bottom: 25px !important;
    right: auto !important;

    .mktoErrorMsg {
      box-shadow: none;
      background-image: none;
      background-color: $verizon-red;
      text-shadow: none;
      border-radius: 0;
      border: none;
    }

    .mktoErrorArrowWrap {
      top: 98%;
      transform: rotate(180deg);

      .mktoErrorArrow {
        background-color: $verizon-red;
        border-color: $verizon-red;
      }
    }
  }

  @media(max-width: 992px) {
    bottom: -1px;
    width:100%;
    height: 35px;
    border-radius: 10px 10px 0 0;
    padding: 12px 30px 0 20px;
    transition: height .3s, padding .2s;
  }

  /* 1792x828px at 326ppi */
  @media only screen
  and (device-width : 414px)
  and (device-height : 896px)
  and (-webkit-device-pixel-ratio : 2) {
    height: 50px;
  }

  /* 2436x1125px at 458ppi */
  @media only screen
  and (device-width : 375px)
  and (device-height : 812px)
  and (-webkit-device-pixel-ratio : 3) {
    height: 50px;
  }

  /* 2688x1242px at 458ppi */
  @media only screen
  and (device-width : 414px)
  and (device-height : 896px)
  and (-webkit-device-pixel-ratio : 3) {
    height: 50px;
  }

  &.expanded {
    width: 240px;
    padding: 10px 20px 10px 35px;

    .widget-header {
      opacity: 0;
      z-index: -1;
      transition: opacity 0s;
    }

    .signup-widget__content-expanded {
      opacity: 1;
      transition-delay: .2s;
    }

    .input-group {
      width: 80%;
      height: auto;
      overflow: visible;

      @media(max-width: 992px) {
        width: 100%;
      }
    }

    @media(max-width: 992px) {
      width: 100%;
      height: 90px;
      padding: 20px 15px 0 15px;

      .input-group {
        margin-top: 7px;
      }
    }

    .signup-widget__handle {
      z-index: 0;
    }
  }

  .signup-form-message {
    color: #fff;
    width: 189px;
    font-size: 20px;
    text-align: center;
    margin-top: -9px;

    @media(max-width: 992px) {
      width: 100%;
      font-size: 14px;
    }
  }

  .signup-widget__content-expanded {
    opacity: 0;
    transition: opacity 0s;

    @media(max-width: 992px) {
      opacity: 1;
    }
  }

  .widget-header,
  .widget-header--expanded {
    color: white;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.1;

    @media(max-width: 992px) {
      font-size: 14px
    }
  }

  .widget-header {
    position: absolute;
    width: 70px;
    left: 30px;
    transition: opacity .2s;
    transition-delay: .1s;

    @media(max-width: 992px) {
      position: relative;
      width: 100%;
    }
  }

  .input-group {
    margin-top: 10px;
    transition: width .2s;
    height: 0;
    overflow: hidden;
    display: block;

    .mktoFormCol {
      width: 100%;
    }

    @media(min-width: 992px) {
      margin-top: 10px;

      .mktoFormRow {
        width: 190px;
      }

      .mktoEmailField {
        width: 100% !important;
      }
    }

    .mktoFieldWrap {
      width: 100%;
    }

    .mktoGutter,
    .mktoOffset {
      display: none;
    }

    .mktoEmailField {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #707070;
      font-style: italic;
      color: white;
      font-size: 16px;
      padding: 5px 4ch 5px 5px;
      height: auto;
      width: 100% !important;

      &:focus {
        outline: none;
      }
    }

    .mktoButtonRow {
      position: static;
    }

    .mktoForm {
      padding: 0;

      @media(max-width: 992px) {
        width: 100% !important;
      }
    }

    .mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
      background-color: $verizon-black;
      position: absolute;
      right: -42px;
      height: 27px;
      width: auto;
      margin-left: -24px;
      border-radius: 0;
      box-shadow: none;
      display: flex;
      align-items: center;
      opacity: 1;
      z-index: 3;
      border: none;
      padding: 0 20px 0 1ch;
      top: -1px;
      background-image: none;
      overflow: hidden;
      color: white;

      @media(max-width: 992px) {
        right: 0;
        top: 7px;
        height: 20px;
      }

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid $verizon-dark-gray;
        border-bottom: 2px solid $verizon-dark-gray;
        transform: rotate(-45deg);
        margin-left: 9px;
        margin-bottom: -2px;
        position: absolute;
        right: 4px;
      }
    }
  }

  &__handle {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100px;
    cursor: pointer;
    z-index: 2;

    .handle-bar__wrapper {
      width: 20px;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 3px 0 5px;
      background-color: $verizon-darkest-gray;

      .handle-bar {
        width: 2px;
        height: 23px;
        background-color: $verizon-dark-gray;
      }

      @media(max-width: 992px) {
        display: none;
      }
    }

    .handle-bar--mobile {
      display: none;
      width: 50px;
      height: 3px;
      background-color: $verizon-dark-gray;
      border-radius: 3px;
      position: absolute;
      top: 4px;
      left: 0;
      right: 0;
      margin: 0 auto;

      @media(max-width: 992px) {
        display: block;
      }
    }

    @media(max-width: 992px) {
      top: 0;
      width:100%;
      left: 0;
      right: 0;
      flex-direction: column;
      height: 38px;
      padding: 0;
      background-color: transparent;

      &:before {
        display: none;
      }
    }
  }
}
