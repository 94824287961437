.navbar-side-wrapper {
  position: fixed;
  right: 50px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);

  @media(max-width: 992px) {
    background-color: white;
    right: -200%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: right .7s;
    transform: translate3d(-4px, -50%, 1px); /* FIX FOR Z-INDEXES ON MOBILE SAFARI */

    &.active {
      right: 0 !important;
    }
  }
}

.navbar-side {
  list-style-type: none;
  text-align: right;
  margin: 0;
  font-weight: bold;

  &.video-popup-open {
    filter: blur(10px);
  }

  @media(max-width: 992px) {
    padding-right: 50px;
    margin-top: 70px;
  }

  a {
    font-size: 18px;
    font-weight: bold;
    color: $verizon-gray;
    transition: font-size .1s, color .1s;

    &:hover {
      font-size: 28px;
      color: $verizon-black;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }

    @media(max-width: 992px) {
      font-size: 22px;

      span {
        font-size: 30px;
        color: #000;
        margin-left: 7px;
      }
    }
  }

  li {
    margin: 5px 0;
    transform-origin: top right;

    &:focus,
    &:active {
      outline: none;
    }

    a {
      &.active {
        font-size: 28px;
        color: $verizon-black;

        @media(max-width: 992px) {
          font-size: 28px;
        }
      }

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.mobile-nav-toggle {
  width: 40px;
  height: 40px;
  border: 2px solid $verizon-black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  box-shadow: none;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;

  @media(min-width: 992px) {
    display: none;
  }
}

.navbar-side__close-button {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 160px;
  right: 20px;
  cursor: pointer;
  display: none;
  background-color: transparent;
  border: none;
  padding: 0;

  img {
    width: 100%;
  }

  @media(max-width: 992px) {
    display: block;
  }

  @media(max-width: 768px)  {
    top: 140px;
  }

  @media(max-width: 350px) {
    width: 20px;
    height: 20px;
    right: 30px;
  }
}