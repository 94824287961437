.intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 1;
  z-index: 1;

  &__container{
    max-width: 1200px;
    position: relative;
    height: 100%;
    margin: 0 auto;
    width: 100%;
  }

  &__image-man {
    height: 90%;
    align-self: center;
    transform-origin: 0 10%;
    position: absolute;

    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;

    @media(max-width: 768px) {
      height: auto;
      width: 90%;
      bottom: -50px;
    }

    @media(max-width: 370px) {
      width: 75%;
    }

    &--desktop {
      @media (max-width: 768px) and (max-aspect-ratio: 3/4){
        display: none;
      }
    }
    &--mobile {
      display: none;
      @media (max-width: 768px) and (max-aspect-ratio: 3/4){
        display: block;
      }
    }
  }

  &__image-ready {
    align-self: center;
    transform-origin: 0 10%;
    position: absolute;
    bottom: 28%;

    margin: 0 auto;
    left: 0;
    right: 0;

    @media(min-width: 768px) and (max-width: 1920px) {
      height: 30%;
    }

    @media(min-width: 768px) and (max-width: 992px) {
      bottom: 20%;
      width: 100%;
      height: auto;
    }

    @media(max-width: 767px) and (max-aspect-ratio: 3/4) {
      bottom: calc(90vw * 1.6 / 3.5);
    }

    @media(max-width: 370px) {
      width: 80%;
      bottom: calc(75vw * 1.6 / 4);
    }
  }

  &__draw-line {
    stroke-dasharray: 0,0,950,950;
    stroke-dashoffset: 950;

    overflow: visible !important;
    display: inline-block;
    vertical-align: baseline;
    width: 1px;
    height: 1px;
    transform: translate(-0.438em, -.06em);
    position: relative;

    line, polyline {
      stroke: black;
      stroke-width: 0.16em;
    }
  }

  &__text-hidden {
    opacity: 0;
    pointer-events: none;
    z-index: -10;
  }
  &__text, &__text-hidden {
    position: absolute;
    top: 10%;
    transform-origin: top left;

    @media(max-width: 768px) {
      top: 7%;
    }

    h1 {
      font-weight: bold;
      font-size: 53px;
      line-height: 1;
      margin-bottom: 10px;

      @include fluid-type($min_width, $max_width, 19px, 53px);

      @media(min-width: 768px) and (max-width: 992px) {
        font-size: 34px;
        margin-bottom: 5px;
      }

      @media(max-width: 400px) {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
  }

  .intro__partners-wrapper {
    position: absolute;
    bottom: calc(10% + 120px);
    padding-right: 20px;
    text-align: left;
    right: 55px;
    width: 150px;

    @media(max-width: 992px) {
      top: 7%;
      right: 15px;
      padding-right: 0;
      width: 100px;
    }

    .partners-header {
      margin-bottom: 5px;
      font-size: 18px;

      @media(max-width: 992px) {
        font-size: 12px;
        text-align: right;
      }
    }

    .partners-logotypes-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media(max-width: 768px) {
        flex-wrap: wrap;
      }

      @media(max-width: 374px) {
        .partners-logotypes-wrapper__mobile {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-around;
          width: 100%;
          margin-left: -15px;
          padding-bottom: 20px;
        }
      }

      img {
        width: 100%;

        @media(max-width: 768px) {
          width: 70%;
          display: block;
          margin: 0;
          float: right;
        }
      }
    }
  }
}
