.scene--collaboration {
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .scene__top-wrapper {
    .scene-text-wrapper {
      flex-basis: 28%;

      @media(max-width: 768px) {
        flex-basis: 100%;
      }
    }
  }

  .scene__bottom-wrapper {
    .big-number-info {
      flex-basis: 24%;
    }
    .big-number-info__text {
      max-width: 200px;
    }

    @media(max-width: 768px) {

      .scene-graphics {
        flex-basis: auto;
        width: 100%;
      }

      .big-number {
        flex-basis: auto;
      }

      .big-number-info {
        flex-basis: auto;

        .big-number-info__text {
          padding-left: 20px;
        }
      }
    }
  }
}
