.promo-ribbon {
  height: 40px;
  width: 100%;
  background-color: black;
  color: white;

  &__content {
    width: 50%;
    float: right;
    padding: 0 15px;
    height: 100%;
    display: flex;
    align-items: center;

    @media(max-width: 768px) {
      justify-content: flex-end;
    }

    p, svg {
      margin: 0;
    }

    p {
      line-height: 1;
      font-size: 12px;

    }

    svg {
      margin-left: 5px;
    }
  }
}

.navbar {
  min-height: 160px;
  border-bottom: 1px solid $verizon-gray;
  z-index: 10;
  overflow: hidden;

  @media(max-width: 768px) {
    height: 120px;
    min-height: 80px;
  }

  .nav {
    .active {
      a {
        color: $verizon-black;
      }
    }
    a {
      color: $verizon-dark-gray;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}

.navbar-header {
  width: 50%;

  @media(max-width: 768px) {
    width: 100%;
  }
}

.navbar-brand {
  img {
    width: 200px;
  }
}