.device-orientation-change-overlay {
  width: 100vw;
  height: calc(100vh - 150px);
  background-color: white;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: fixed;
  bottom: 30px;

  @media (max-width: 768px) and  (orientation : landscape) {
    display: flex;
  }

  h2 {
    color: black;
  }
}