.scroll-indicator {
  position: absolute;
  left: 0;
  top: 110%;
  margin: 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;

  p {
    font-size: 24px;

    @media(max-width: 992px) {
      font-size: 22px;
    }

    @media(max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0;
    }

    @media(max-width: 540px) {
      font-size: 12px;
    }
  }


  &__arrow {
    width: 30px;
    margin-left: 20px;
    animation: bounce 1s infinite ease-in-out;

    @media(max-width: 992px) {
      margin-left: 10px;
      width: 20px;
    }

    @media(max-width: 768px) {
      width: 15px;
    }
  }

  @media(max-width: 992px) {
    top: 100%;
    left: 0;
    margin-top: 10px;
  }

  @media(max-width: 768px) {
    margin-top: -5px;
  }
}

@keyframes bounce {
  0% { transform: translateY(-3px)  }
  50% { transform: translateY(7px) }
  100% { transform: translateY(-3px) }
}